@import "styles/core.scss";
$desktop-cta-bar-height: 64px;
$admin-cta-bar-height: 64px;
$max-mobile-lcp-height: 46vh;
$min-mobile-lcp-height: 260px;

// Double selector needed to apply the 0 margin
.mainPageSection.mainPageSection {
  @include flex-row;
  gap: var(--space-600);

  @include page-width-md-down {
    @include flex-column;
  }

  @include page-width-sm-down {
    margin: 0;
  }

  @include page-width-xs {
    margin: 0;
  }
}

.responsiveMainColumn {
  width: 100%;

  @include page-width-lg-up {
    width: calc(50% - var(--space-300));
  }
}

.desktopMapColumn {
  display: none;

  @include page-width-lg-up {
    display: block;
    width: calc(50% - var(--space-300));
    height: calc(100vh - 148px);
    position: sticky;
    top: 84px;
    padding-bottom: var(--space-200);

    &.hasAdminToolbar {
      height: calc(100vh - 148px - $admin-cta-bar-height);
      top: calc(84px + $admin-cta-bar-height);
    }
  }
}

.resultsHeader {
  @include heading-400;
  margin-top: 0;
  margin-bottom: 0;

  @include page-width-lg-up {
    margin: 0;
    padding-top: var(--space-800);
  }
}

.paddedSection {
  padding: 0 var(--space-300);

  @include page-width-md-up {
    padding: 0;
  }

  @include page-width-lg-up {
    margin-right: calc(-1 * var(--space-200)); // gives the appearance of 32px gap because of the padding needed for hover state
  }
}

.mobileTabletMap {
  border-radius: var(--radius-lg);
  width: 100%;
  margin-top: var(--space-300);

  @include page-width-lg-up {
    display: none;
  }
}

.breadcrumbsRowContainer {
  display: flex;
  background-color: var(--color-background-primary);

  @include page-width-md-up {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky);

    &.hasAdminToolbar {
      top: $admin-cta-bar-height;
    }
  }
}

.breadcrumbsRow {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: calc(var(--breakpoint-lg-size) - (2 * var(--breakpoint-lg-margin)));
  margin: 0 auto;
  transition: all 0.3s ease-in-out;

  @include page-width-lg-down {
    margin: 0 var(--breakpoint-lg-margin);
  }

  @include page-width-md-down {
    margin: var(--space-200) var(--breakpoint-md-margin);
  }

  @include page-width-sm-down {
    margin: 0 auto;
  }
}

.stickyBreadcrumbBar {
  box-shadow: var(--shadow-100);
  height: $desktop-cta-bar-height;
  transition: all 0.3s ease-in-out;
}

.breadcrumbs {
  opacity: 1;
  transition: opacity 0.1s ease-out;
  max-width: 100%;
  z-index: var(--z-index-default);
}

.hideBreadcrumbs {
  opacity: 0;
  z-index: unset;
}

.imageAndButtonsContainer {
  @include page-width-lg-up {
    width: 100%;
    aspect-ratio: 1.5;
    margin-bottom: var(--space-40);

    &.noImages {
      aspect-ratio: unset;
      margin-bottom: 0;
    }

    &.photoGrid {
      aspect-ratio: 2.09;
      margin-bottom: var(--space-300);
    }
  }

  @include page-width-sm-down {
    position: sticky;
    top: 0;
    z-index: 0;
  }
}

.imageContainer {
  @include page-width-sm-down {
    height: $max-mobile-lcp-height;
    min-height: $min-mobile-lcp-height;
    will-change: height;
  }

  @include page-width-md-down {
    width: 100%;
    aspect-ratio: 1.5;
    max-height: 480px;

    &.noImages {
      aspect-ratio: unset;
    }
  }

  &.photoGrid {
    @include page-width-md-down {
      aspect-ratio: 2.09;
    }
    @include page-width-sm-down {
      aspect-ratio: 1.5;
    }
  }
}

.mobileButtons {
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  width: 100%;
  z-index: var(--z-index-default);
  padding: var(--space-300) var(--space-300) 0;

  @include page-width-md-up {
    display: none;
  }
}

.stickyBar {
  position: fixed;
  height: $desktop-cta-bar-height;
  width: 100%;
  padding: 14px var(--space-300);
  background-color: var(--color-background-primary);
  top: -$desktop-cta-bar-height;
  left: 0;
  z-index: var(--z-index-sticky);
  will-change: top;

  box-shadow: var(--shadow-100);
  transition: top var(--duration-short) ease-out;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.showStickyBar {
  top: 0;

  &.hasAdminToolbar {
    top: $admin-cta-bar-height;
  }
}

.desktopButtons {
  display: none;

  @include page-width-md-up {
    display: block;
  }
}

.wrapperForMobile {
  @include page-width-md-down {
    padding: 40px 0 0;
    position: relative;
    background-color: var(--color-background-primary);

    &.noImages {
      padding-top: 0;
    }
  }

  @include page-width-sm-down {
    padding: var(--space-300) 0 0;
    margin-top: -32px;
    border-top-right-radius: var(--radius-lg);
    border-top-left-radius: var(--radius-lg);

    &.noImages {
      padding-top: var(--space-300);
    }
  }
}

.stickyLocationTitle {
  display: none;
  position: absolute;

  @include page-width-md-up {
    display: block;
    @include text-100-bold;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }
}

.showStickyLocationTitle {
  @include page-width-md-up {
    opacity: 1;
  }
}

.tabletStickyHeader {
  @include page-width-md-up {
    a,
    button {
      // print, share, directions buttons:
      animation: resize 0.3s forwards;
      transition:
        width 0.3s ease-in-out,
        height 0.3s ease-in-out;

      svg {
        // print, share, directions icons
        animation: resizeSvg 0.3s forwards;
        transition:
          width 0.3s ease-in-out,
          height 0.3s ease-in-out;
      }
    }

    svg {
      // plus badge on print button
      animation: resizePlusSvg 0.3s forwards;
      transition:
        width 0.3s ease-in-out,
        height 0.3s ease-in-out;
    }
  }
}

@keyframes resize {
  0% {
    width: 48px;
    height: 48px;
  }
  100% {
    width: 36px;
    height: 36px;
  }
}

@keyframes resizeSvg {
  0% {
    width: 24px;
    height: 24px;
  }
  100% {
    width: 20px;
    height: 20px;
  }
}

@keyframes resizePlusSvg {
  0% {
    width: 16px;
    height: 16px;
  }
  100% {
    width: 12px;
    height: 12px;
  }
}

.resultsList {
  @include flex-column;
  margin-top: var(--space-300);

  @include page-width-md-down {
    margin-top: var(--space-600);
  }

  @include page-width-sm-down {
    margin-top: 32px;
  }

  @include page-width-lg-up {
    margin-top: 20px; // each card has 16px margin, so to make the gap between the title and the top of the first card = 36px, we only need 20px here
    margin-left: -16px;
  }
}

.content {
  @include flex-column;
  gap: var(--space-1200);

  &.addBottomMargin {
    margin-bottom: var(--breakpoint-lg-margin);
  }

  @include page-width-md-down {
    gap: var(--breakpoint-lg-margin);

    &.addBottomMargin {
      margin-bottom: var(--breakpoint-md-margin);
    }
  }

  @include page-width-sm-down {
    gap: var(--space-600);
    z-index: 1;
  }

  // add gap before content elements
  &:before {
    @include page-width-md-down {
      content: '';
    }
  }
}

.adminToolbarContainer {
  background-color: var(--color-background-primary);
  border-top: var(--borders-weight-sm) solid var(--color-border-separator);
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  transition: all 0.3s ease-in-out;

  @include page-width-sm-down {
    position: sticky;
    top: 0;
    z-index: var(--z-index-dropdown);
  }

  @include page-width-md-up {
    position: sticky;
    top: 0;
    z-index: var(--z-index-sticky);
  }
}

.adminToolbar {
  width: 100%;
  max-width: calc(var(--breakpoint-lg-size) - (2 * var(--breakpoint-lg-margin)));
  margin: 0 auto;
  transition: all 0.3s ease-in-out;

  @include page-width-lg-down {
    width: unset;
    margin: 0 var(--breakpoint-lg-margin);
  }

  @include page-width-md-down {
    margin: 0 var(--breakpoint-md-margin);
  }

  @include page-width-sm-down {
    margin: 0 var(--space-300);
  }
}
