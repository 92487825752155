@import "styles/core.scss";
$carousel-breakpoint: 1440px;

@mixin noImage {
  @include flex-row;
  justify-content: center;
  align-items: center;
  background-color: rgba(76, 64, 26, 0.08);
}

.topResultsContainer {
  @include flex-column;

  @include page-width-lg-up {
    padding-bottom: 32px;
  }
}

.border {
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  margin: var(--space-100);

  @include page-width-sm-up {
    display: none;
    margin: 0;
  }
}

.topResultCardContainer {
  cursor: pointer;
  @include flex-row;
  align-items: center;

  @include page-width-md-down {
    padding-bottom: var(--space-100);
    &:not(:last-of-type) {
      border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
    }

    &:not(:first-of-type) {
      padding-top: var(--space-100);
    }
  }

  @include page-width-lg-up {
    @include focusOrHover {
      background-color: var(--color-neutral-50);
      border-radius: var(--radius-xl);
    }
  }
}

.resultRanking {
  margin-right: var(--space-200);
  width: 22px;
  @include heading-300;
  color: var(--color-text-secondary);

  @include page-width-lg-up {
    display: none;
  }
}

.resultCardContent {
  @include flex-row;
  width: 100%;
  align-items: center;
  gap: var(--space-150);
  padding: var(--space-150) 0;

  @include page-width-lg-up {
    align-items: flex-start;
    padding: var(--space-200);
  }
}

.titleAndStats {
  @include flex-column;
  gap: 2px;
  width: 100%;
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.resultStatsLink.resultStatsLink {
  text-decoration: none;

  @include page-width-lg-up {
    min-height: 16px;
  }
}

.resultStats {
  @include flex-row;
  align-items: center;
}

.star {
  @include flex-row;
  color: var(--color-text-secondary);

  @include page-width-lg-up {
    color: var(--color-text-primary);
  }

  svg {
    vertical-align: sub; // Allows for vertical centering without using flex, which makes truncation much cleaner
    pointer-events: none;
    width: 12px;
    height: 12px;
    margin-right: var(--space-50);
  }
}

.textContent {
  @include flex-row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include text-100;
  color: var(--color-text-secondary);

  @include page-width-lg-up {
    color: var(--color-text-primary);
    @include text-200;
  }
}

.rating {
  margin: 0 2px;
}

.reviews {
  @include page-width-md-down {
    display: none;
  }
}

.resultStat {
  @include flex-row;
  align-items: center;
}

.infoDot {
  @include page-width-lg-up {
    color: var(--color-text-primary);
  }
}

.estimatedTime {
  overflow: hidden;
  text-overflow: ellipsis;

  @include page-width-md-down {
    display: none;
  }
}

.resultDescription {
  @include text-200;
  color: var(--color-text-secondary);
  margin-top: var(--space-200);

  @include page-width-md-down {
    display: none;
  }

  span {
    color: var(--color-text-secondary);
  }
}

.rankNameAndBookmark {
  @include flex-row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--space-300);
}

.desktopBookmark {
  @include page-width-md-down {
    display: none;
  }
}

// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.mobileBookmark.mobileBookmark {
  padding: var(--space-100);
  margin: 0;

  @include page-width-lg-up {
    display: none;
  }
}

.resultName {
  flex: 1;
  @include lineClamp(2);

  @include page-width-sm-down {
    @include text-200-bold;
  }
  @include heading-300;
}

.desktopRanking {
  @include page-width-md-down {
    display: none;
  }
}

.resultImage {
  object-fit: cover;
  object-position: center;
  border-radius: var(--radius-md);

  @include page-width-md-down {
    min-width: 64px;
    height: 64px;
  }

  @include page-width-lg-up {
    min-width: 180px;
    height: 180px;
  }

  @include minWidth($carousel-breakpoint) {
    min-width: 240px;
    height: 180px;
  }

  &.noImage {
    @include noImage;
  }
}

.titleAndBookmark {
  @include flex-row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: var(--space-100);

  @include page-width-lg-up {
    align-self: stretch;
  }
}

.imageContainer {
  border-radius: var(--radius-md);
  isolation: isolate; // Prevents photo overflow on safari https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  background-color: var(--color-neutral-200);
  overflow: hidden;

  @include page-width-md-down {
    min-width: 64px;
    height: 64px;
    aspect-ratio: 1;
  }

  @include page-width-lg-up {
    min-width: 180px;
    height: 180px;
    aspect-ratio: 1;
  }

  @include minWidth($carousel-breakpoint) {
    min-width: 240px;
    height: 180px;
    aspect-ratio: 1.33;
  }

  img {
    transition: transform 0.3s ease-in-out !important;
  }

  &.zoomPhoto .image {
    @include cardImageZoom;
  }

  &.noImage {
    @include noImage;
  }
}

.image {
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
  object-position: center;
  border-radius: var(--radius-md);

  @include page-width-md-down {
    min-width: 64px;
    height: 64px;
  }

  @include page-width-lg-up {
    min-width: 180px;
    height: 180px;
  }

  @include minWidth($carousel-breakpoint) {
    min-width: 240px;
    height: 180px;
  }
}
// temporary workaround for styling import order issue
// https://github.com/vercel/next.js/issues/65480
.exploreMore.exploreMore {
  display: none;

  @include page-width-md-down {
    display: inline-flex;
    margin-top: var(--space-600);
  }

  @include page-width-sm-down {
    margin-top: 32px;
  }
}

.showMoreAreas {
  @include flex-column;
  margin-top: var(--space-300);
  justify-content: center;
  align-items: center;
  gap: var(--space-200);
}

.showMoreSection {
  @include flex-column;
  margin-top: var(--space-450);
  justify-content: center;
  align-items: center;

  @include page-width-md-down {
    margin-top: var(--space-300);
  }
}

.showMoreButton {
  @include page-width-md-down {
    width: 100%;
  }
}

.showing {
  @include text-100;
  color: var(--color-text-secondary);
  margin-top: var(--space-150);
}
