@import "styles/core.scss";
.headerImageWrapper {
  width: 100%;
  height: 40vh;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 39.93%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 55.07%, rgba(0, 0, 0, 0.64) 100%), rgba(0, 0, 0, 0.16);

  @include page-width-md-up {
    height: 55vh;
    margin-bottom: 36px;
  }
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; // Sits behind gradient on the wrapper
}

.heroImageMobile {
  @include page-width-md-up {
    display: none;
  }
}

.heroImageDesktop {
  display: none;

  @include page-width-md-up {
    display: block;
  }
}

.header {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.margins {
  margin: 0 var(--space-300);
  max-width: 1240px;

  @include page-width-md-up {
    margin: 0 var(--space-600);
  }

  @include page-width-lg-up {
    margin: 0 100px;
  }

  @include minWidth(1440px) {
    margin: 0 auto;
  }
}

.heading {
  max-width: 300px;
  margin: 0 0 var(--space-600) 0;
  @include heading-600;
  color: var(--color-neutral-white);

  @include page-width-md-up {
    max-width: 550px;
    @include heading-700;
    color: var(--color-neutral-white);
  }
}
