@import "styles/core.scss";
.flexContainer {
  @include flex-column;
  align-items: center;
}

.margins {
  margin: 0 var(--space-300);
  max-width: 1240px;

  @include page-width-md-up {
    margin: 0 var(--space-600);
  }

  @include page-width-lg-up {
    margin: 0 100px;
  }

  @include minWidth(1440px) {
    margin: 0 auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  padding: 60px var(--space-200) 0 var(--space-200);

  @include page-width-sm-up {
    padding: 60px var(--space-300) 0 var(--space-300);
  }

  @include page-width-md-up {
    padding: 72px var(--space-600) 0 var(--space-600);
  }

  @include page-width-lg-up {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    max-width: 1440px;
  }
}

.heading {
  margin-top: 0;
  margin-bottom: var(--space-450);
  @include heading-500;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  padding: var(--space-600) var(--space-200);

  @include page-width-sm-up {
    padding: var(--space-600) var(--space-300);
  }

  @include page-width-md-up {
    padding: var(--space-600);
  }

  @include page-width-lg-up {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    max-width: 1440px;
  }
}

.subheader {
  @include heading-400;
  margin-top: 0;
}

.generalContent {
  @include text-200;
  margin-top: 0;
  margin-bottom: var(--space-300);
  max-width: 800px;

  @include page-width-md-up {
    @include text-300;
    margin-bottom: var(--space-450);
  }

  @include page-width-lg-up {
    margin-bottom: var(--space-600);
  }
}
