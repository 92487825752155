@import "styles/core.scss";
.loading {
  background-color: var(--color-background-secondary);
  border-radius: inherit;
  width: 100%;
  height: 100%;

  img {
    // Remove the default tan-colored backgroundImage that covers up the loading state
    background-image: none !important; // important is required here because the style is applied as an inline style within next
  }
}
