@import "styles/core.scss";
$loadingBackground: rgba(76, 64, 26, 0.08);

.carousel {
  z-index: 0;
}

.linkContainer {
  width: 100%;
  display: inline-flex;
  height: 100%;
}

.itemImageContainer {
  width: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
    mix-blend-mode: multiply;
    border-radius: var(--radius-lg);
  }
}

.topModuleImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 2/3;
  object-fit: cover;

  @include page-width-sm-down {
    aspect-ratio: 3/4;
  }
}

.noImage {
  width: 100%;
  height: 100%;
  background-color: rgba(76, 64, 26, 0.08);
  @include flex-row;
  justify-content: center;
  align-items: center;
}

.titleAndButtonContainer {
  @include flex-row;
  justify-content: space-between;
  margin-bottom: var(--space-600);

  @include page-width-xs {
    margin-bottom: 32px;
  }

  @include page-width-sm-down {
    margin-bottom: var(--space-450);
  }
}

.moduleTitle {
  @include heading-600;
  @include flex-row;
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;

  @include page-width-sm-down {
    @include heading-400;
  }
}

.imageAndTitleContainer {
  overflow: hidden;
  height: 100%;
  border-radius: var(--radius-lg);

  @include page-width-sm-up {
    @include hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.itemTitle {
  @include heading-400;
  color: var(--color-text-light);
  position: absolute;
  left: var(--space-300);
  bottom: var(--space-300);
  margin-right: var(--space-300);
  cursor: pointer;
}

.desktopShowMore {
  display: none;

  @include page-width-md-up {
    display: flex;
  }
}

.mobileShowMore {
  margin-top: 32px;
  display: none;
  align-self: flex-start;

  @include page-width-sm-down {
    display: flex;
  }
}

.loadingBackground {
  background-color: $loadingBackground;
  width: 100%;
  height: 100%;
  @include flex-column;
  gap: var(--space-150);
  justify-content: flex-end;
  padding: var(--space-300);
}

.title1 {
  height: 22px;
  background-color: $loadingBackground;
}

.title2 {
  height: 22px;
  width: 70%;
  background-color: $loadingBackground;
}
